<template>
  <a-modal
    :title="model ? '修改兑换码' : '新建兑换码'"
    :width="640"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <!-- 检查是否有 id 并且大于0，大于0是修改。其他是新增，新增不显示主键ID -->
        <a-form-item v-show="model && model.id > 0" label="ID">
          <a-input v-decorator="['id', { initialValue: 0 }]" disabled />
        </a-form-item>
        <a-form-item v-show="!model || !(model.id > 0)" label="数量">
          <a-input v-decorator="['count', {rules: [{required: true}]}]" type="number"/>
        </a-form-item>
        <a-form-item label="兑换类型">
          <a-select v-decorator="['type', {rules: [{required: true}], initialValue: 'NONE'}]" :disabled="!!model">
            <a-select-option v-for="t in codeType" :value="t.id" :key="t.label">
              {{ t.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <!--        maimai DX        -->
        <div v-if="currentType === 'MAI2'">
          <a-form-item label="maimaiDX兑换类型">
            <a-select v-decorator="['configType', {rules: [{required: true}], initialValue: 'DX_PASS'}]" :disabled="!!model">
              <a-select-option v-for="t in mai2CodeType" :value="t.id" :key="t.label">
                {{ t.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <!--        maimai DX 【DX PASS】       -->
          <div v-if="configType === 'DX_PASS'">
            <a-form-item label="DX PASS类型">
              <a-select v-decorator="['mai2.cardTypeId', {rules: [{required: true}], initialValue: 4}]" :disabled="!!model">
                <a-select-option v-for="t in mai2DxPassType" :value="t.id" :key="t.label">
                  {{ t.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="有效期天数">
              <a-input v-decorator="['mai2.validDay', {rules: [{required: true}], initialValue: 15}]" type="number"/>
            </a-form-item>
          </div>
          <!--        maimai DX 【ITEM】       -->
          <div v-if="configType === 'ITEM'">
            <a-form-item label="收藏品类型">
              <a-select v-decorator="['mai2.itemKind', {rules: [{required: true}], initialValue: 2}]" :disabled="!!model">
                <a-select-option v-for="t in mai2ItemKind" :value="t.id" :key="t.label">
                  {{ t.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="收藏品ID">
              <a-input v-decorator="['mai2.itemId', {rules: [{required: true}], initialValue: 0}]" type="number"/>
            </a-form-item>
            <a-form-item label="数量">
              <a-input v-decorator="['mai2.stock', {rules: [{required: true}], initialValue: 1}]" type="number"/>
            </a-form-item>
          </div>
          <!--        maimai DX 【TICKET】       -->
          <div v-if="configType === 'TICKET'">
            <a-form-item label="道具票">
              <a-select v-decorator="['mai2.ticketId', {rules: [{required: true}], initialValue: 20020}]" :disabled="!!model">
                <a-select-option v-for="t in mai2Ticket" :value="t.id" :key="t.label">
                  {{ t.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="数量">
              <a-input v-decorator="['mai2.count', {rules: [{required: true}], initialValue: 5}]" type="number"/>
            </a-form-item>
          </div>
        </div>
        <!--        <a-form-item label="配置">-->
        <!--          <a-input v-decorator="['config', {rules: [{required: true}]}]" />-->
        <!--        </a-form-item>-->
        <a-form-item label="过期时间">
          <a-date-picker show-time v-decorator="['expiredTime', {rules: [{required: true}], initialValue: ''}]" />
        </a-form-item>
        <a-form-item label="重复兑换">
          <a-select v-decorator="['unlimited', {rules: [{required: true}], initialValue: false}]">
            <a-select-option :value="false">
              否
            </a-select-option>
            <a-select-option :value="true">
              是
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="备注">
          <a-input v-decorator="['remarks']" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { Mai2DxPassType, Mai2RedeemCodeType, Mai2Ticket, RedeemCodeType } from '@/model/redeemConst'
import { Mai2ItemKind } from '@/model/mai2const'

// 表单字段
const fields = ['type', 'config', 'expiredTime', 'remarks']

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      currentType: 'WEB',
      configType: 'DX_PASS',
      form: this.$form.createForm(this, {
        onValuesChange: (_, fields) => {
          if (fields.type) {
            this.currentType = fields.type
          }
          if (fields.configType) {
            this.configType = fields.configType
          }
        }
      }),
      codeType: RedeemCodeType,
      mai2CodeType: Mai2RedeemCodeType,
      mai2DxPassType: Mai2DxPassType,
      mai2ItemKind: Mai2ItemKind,
      mai2Ticket: Mai2Ticket
    }
  },
  created () {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  }
}
</script>
