import { render, staticRenderFns } from "./RedeemCodeGroupList.vue?vue&type=template&id=8630b508&scoped=true&"
import script from "./RedeemCodeGroupList.vue?vue&type=script&lang=js&"
export * from "./RedeemCodeGroupList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8630b508",
  null
  
)

export default component.exports