<template>
  <page-header-wrapper>
    <a-card :bordered="false">

      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
      </div>
      <s-table
        ref="table"
        size="default"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data="loadData"
      >
        <span slot="action" slot-scope="text, record">
          <template>
            <router-link :to="`/web/redeem/` + record.id">查看兑换码</router-link>
            <!--            <a-divider type="vertical" />-->
            <!--            <a @click="handleAdd">编辑</a>-->
            <!--            <a-popconfirm-->
            <!--              title="你确定要停用吗?"-->
            <!--              ok-text="Yes"-->
            <!--              cancel-text="No"-->
            <!--              @confirm="handleDelete(record)"-->
            <!--            >-->
            <!--              <a href="#">停用</a>-->
            <!--            </a-popconfirm>-->
          </template>
        </span>
      </s-table>

      <redeem-code-create-form
        ref="createModal"
        :visible="formVisible"
        :loading="confirmLoading"
        :model="formModel"
        @cancel="handleCancel"
        @ok="handleSave"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable } from '@/components'
import { createRedeemGroup, getRedeemGroupList } from '@/api/web'
import RedeemCodeCreateForm from '@/views/web/modules/RedeemCodeCreateForm'

export default {
  name: 'RedeemCodeGroupList',
  components: {
    STable,
    RedeemCodeCreateForm
  },
  data () {
    return {
      formVisible: false,
      confirmLoading: false,
      formModel: null,
      queryParam: {},
      loadData: parameter => {
        return getRedeemGroupList(Object.assign(parameter, this.queryParam))
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: true
        },
        {
          title: '类型',
          dataIndex: 'type',
          sorter: true
        },
        {
          title: '配置',
          dataIndex: 'config'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          sorter: true
        },
        {
          title: '过期时间',
          dataIndex: 'expiredTime',
          sorter: true
        },
        {
          title: '备注',
          dataIndex: 'remarks'
        },
        {
          title: '数量',
          dataIndex: 'codeList',
          customRender: (record) => record.length
        },
        {
          title: '重复兑换',
          dataIndex: 'unlimited',
          customRender: (record) => record ? '是' : '否'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  methods: {
    handleAdd () {
      this.$refs.createModal.form.resetFields()
      this.formModel = null
      this.formVisible = true
    },
    handleSave () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        console.log(values)
        if (!errors) {
          const request = {
            remarks: values.remarks,
            type: values.type,
            count: values.count,
            expiredTime: values.expiredTime,
            unlimited: values.unlimited
          }
          if (values.type === 'MAI2') {
            const config = {
              type: values.configType,
              param: JSON.stringify(values.mai2)
            }
            request.config = JSON.stringify(config)
          }
          createRedeemGroup(request).then(() => {
            this.formVisible = false
            this.confirmLoading = false
            form.resetFields()
            this.$refs.table.refresh()
            this.$message.success('创建成功')
          }).catch(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.formVisible = false
    }
  }
}
</script>

<style scoped>

</style>
