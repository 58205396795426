export const RedeemCodeType = [
  { id: 'WEB', label: '网站' },
  { id: 'MAI2', label: 'maimaiDX' },
  { id: 'CHUNI', label: '中二' },
  { id: 'ONGEKI', label: '音击' }
]
export const Mai2RedeemCodeType = [
  { id: 'DX_PASS', label: 'DX PASS' },
  { id: 'ITEM', label: '收藏品' },
  { id: 'TICKET', label: '票' }
]
export const Mai2DxPassType = [
  { id: 4, label: 'GOLD PASS' },
  { id: 6, label: 'FREEDOM PASS' }
]
export const Mai2Ticket = [
  { id: 11002, label: '2倍票' },
  { id: 11003, label: '3倍票' },
  { id: 11005, label: '5倍票' },
  { id: 20010, label: '10倍票' },
  { id: 20020, label: '20倍票' }
]
